import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDateSelect } from 'react-ymd-date-select';

import styles from './DateSelector.module.scss';

export const DateSelector = ({
  name,
  value,
  no_day,
  firstYear,
  lastYear,
  outputFormat = 'DD/MM/YYYY',
  className,
  onChange = () => {},
  error = '',
}) => {
  const [date, setDate] = useState(() => {
    if (value) {
      const dateValue = moment(value, outputFormat);
      return dateValue.isValid() ? dateValue.format('YYYY-MM-DD') : '';
    }
    return '';
  });

  const hasUserInteracted = useRef(false);

  const dateSelect = useDateSelect(date, setDate, {
    firstYear: firstYear || moment().year() - 100,
    lastYear: lastYear || moment().year() + 10,
    monthFormat: 'MMMM',
  });

  // Create reversed year options once
  const reversedYearOptions = [...dateSelect.yearOptions].reverse();

  // Update date state if value prop changes (for pre-filling from DB)
  useEffect(() => {
    if (value) {
      const dateValue = moment(value, [outputFormat, 'YYYY-MM-DD']);
      if (dateValue.isValid()) {
        setDate(dateValue.format('YYYY-MM-DD'));
      }
    }
  }, [value]);

  // Send date value in output format
  useEffect(() => {
    if (!date) {
      if (hasUserInteracted.current) {
        onChange('');
      }
      return;
    }
    let formattedDate;
    if (no_day) {
      // For no_day, always use the 1st of the month but preserve the selected month and year
      const dateObj = moment(date);
      formattedDate = dateObj.date(1).format(outputFormat);
    } else {
      formattedDate = moment(date).format(outputFormat);
    }

    onChange(formattedDate);
  }, [date]);

  return (
    <div className={cn(styles.root, { [className]: className })}>
      {/* Hidden input for form registration - use the formatted value */}
      <input type="hidden" name={name} value={date ? moment(date).format(outputFormat) : ''} />

      <select
        value={dateSelect.monthValue}
        onChange={e => {
          dateSelect.onMonthChange(e);
          hasUserInteracted.current = true;
          // Ensure the form gets updated immediately
          if (e.target.value && dateSelect.yearValue) {
            const newDate = moment(
              `${dateSelect.yearValue}-${e.target.value.padStart(2, '0')}-${no_day ? '01' : (dateSelect.dayValue || '01').padStart(2, '0')}`,
              'YYYY-MM-DD',
            );
            onChange(newDate.format(outputFormat));
          }
        }}
        className={styles.select}>
        <option key="" value="">
          Month
        </option>
        {dateSelect.monthOptions.map(monthOption => (
          <option key={monthOption.value} value={monthOption.value}>
            {monthOption.label}
          </option>
        ))}
      </select>

      {!no_day && (
        <select
          value={dateSelect.dayValue}
          onChange={e => {
            dateSelect.onDayChange(e);
            hasUserInteracted.current = true;
            // Ensure the form gets updated immediately
            if (e.target.value && dateSelect.monthValue && dateSelect.yearValue) {
              const newDate = moment(
                `${dateSelect.yearValue}-${dateSelect.monthValue.padStart(2, '0')}-${e.target.value.padStart(2, '0')}`,
                'YYYY-MM-DD',
              );
              onChange(newDate.format(outputFormat));
            }
          }}
          className={styles.select}>
          <option key="" value="">
            Day
          </option>
          {dateSelect.dayOptions.map(dayOption => (
            <option key={dayOption.value} value={dayOption.value}>
              {dayOption.label}
            </option>
          ))}
        </select>
      )}

      <select
        value={dateSelect.yearValue}
        onChange={e => {
          dateSelect.onYearChange(e);
          hasUserInteracted.current = true;
          // Ensure the form gets updated immediately
          if (e.target.value && dateSelect.monthValue) {
            const newDate = moment(
              `${e.target.value}-${dateSelect.monthValue.padStart(2, '0')}-${no_day ? '01' : (dateSelect.dayValue || '01').padStart(2, '0')}`,
              'YYYY-MM-DD',
            );
            onChange(newDate.format(outputFormat));
          }
        }}
        className={styles.select}>
        <option key="" value="">
          Year
        </option>
        {reversedYearOptions.map(yearOption => (
          <option key={yearOption.value} value={yearOption.value}>
            {yearOption.label}
          </option>
        ))}
      </select>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

DateSelector.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  no_day: PropTypes.bool,
  firstYear: PropTypes.number,
  lastYear: PropTypes.number,
  outputFormat: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  register: PropTypes.func,
  validators: PropTypes.object,
};
