import { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { ADDRESS_FIELDS } from 'constants/address-structure';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import functionValidators from 'helpers/validators/function-validators';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { CustomButton } from '../components/CustomButton/CustomButton';
import { DynamicTypeInput } from 'components/Form/DynamicTypeInput/DynamicTypeInput';
import { Button, InputGroup } from 'components/FormComponents';
import { FormMessage, MessageType } from 'components/FormComponents/FormMessage/FormMessage';

import {
  saveCandidateChecksValues,
  selectCandidateApplication,
  updateDynamicFields,
  updateIdFieldSelection,
  updateIdUploadOptions,
} from 'api/candidate-application-slice';

import clearHiddenValues from '../../../helpers/candidate_application/clear-hidden-values';
import {
  combineIdTypes,
  processSelectedIds,
} from '../../../helpers/candidate_application/id-requirements';
import { getAllNames } from '../../../helpers/candidate_application/name-versions';
import evaluateFieldConditions from '../../../helpers/evaluate-field-conditions';
import addValidators from '../../../helpers/validators/apply-validators';
import styles from './CandidateDetails.module.scss';

const processAndUpdateIds = (allIdCodes, values, idRequirements, fields, dispatch) => {
  const processedIds = processSelectedIds(allIdCodes, values, fields);
  dispatch(updateIdFieldSelection(processedIds));

  if (idRequirements?.defaultIdTypes) {
    const combinedOptions = combineIdTypes(idRequirements.defaultIdTypes, processedIds);
    dispatch(updateIdUploadOptions(combinedOptions));
  }
};

const CandidateDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token } = useParams();

  const {
    application: { brand, check_values, locale },
    fetched,
    details,
    idRequirements,
  } = useSelector(selectCandidateApplication);

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    unregister,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty, touchedFields, dirtyFields },
    control,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [showErrorList, setShowErrorList] = useState(false);

  const [dynamicFields, setDynamicFields] = useState({});

  const [watchSubscription, setWatchSubscription] = useState(null);

  const [wrapperValidators, setWrapperValidators] = useState({});

  const values = useWatch({ control });

  // Initialize form data
  useEffect(() => {
    if (!fetched || !details?.fields) return;

    // Register complex fields
    const complexFields = (details?.fields || []).filter(
      field =>
        (field.type === 'object' || field.type === 'dynamic_array' || field.type === 'array') &&
        field.validator_name,
    );

    complexFields.forEach(field => {
      setWrapperValidators(wrapperErrors => ({
        ...wrapperErrors,
        [field.code]: {
          code: field.code,
          validator: functionValidators[field.validator_name],
          params: field.validation_params,
          error: null,
        },
      }));
    });

    // Set initial values
    if (check_values?.values) {
      reset({
        ...check_values.values,
        submitted: true,
      });
    }

    // Setup dynamic arrays
    const dynamicArrays = (details?.fields || [])?.filter(field => field.type === 'dynamic_array');
    dynamicArrays.forEach(field => {
      const fieldValues = check_values?.values?.[field.code] || [];
      const positions = [];

      if (fieldValues.length > 0) {
        for (let i = 0; i < fieldValues.length; i++) {
          positions.push(i);
        }
      } else if (field.array_start_initialized) {
        positions.push(0);
      }

      setDynamicFields(curr => ({ ...curr, [field.code]: positions }));
    });
  }, [fetched]);

  useEffect(() => {
    if (!details?.fields || !idRequirements?.idFieldCodes) return;
    const initialValues = getValues();
    processAndUpdateIds(
      idRequirements.idFieldCodes,
      initialValues,
      idRequirements,
      details.fields,
      dispatch,
    );
  }, [details?.fields, idRequirements?.idFieldCodes]);

  useEffect(() => {
    // Existing dynamic fields update
    dispatch(updateDynamicFields(values));

    Object.values(wrapperValidators).forEach(({ code, validator, params }) => {
      const result = validator.validate(values[code], params, values, code);

      setWrapperValidators(wrapperValidators => ({
        ...wrapperValidators,
        [code]: {
          code,
          validator,
          params,
          error: result === true ? null : result,
        },
      }));
    });
  }, [values]);

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name) {
        trigger(name); // only validate the field that just changed
      }

      // Process all ID fields any time an ID field changes
      const allIdCodes = idRequirements.idFieldCodes || [];
      if (name && allIdCodes.includes(name)) {
        processAndUpdateIds(allIdCodes, values, idRequirements, details.fields, dispatch);
      }
    });

    setWatchSubscription(subscription);
    return () => subscription.unsubscribe();
  }, [watch]);

  const anyComplexInputsInvalid = useMemo(() => {
    let valid = true;

    Object.values(wrapperValidators).forEach(({ error }) => {
      if (error) {
        valid = false;
      }
    });

    return !valid;
  }, [wrapperValidators]);

  const addToDynamicArray = field => {
    const currentValues = getValues(field.code) || [];
    const newIndex = currentValues.length;

    // Update dynamic fields state
    setDynamicFields(curr => ({
      ...curr,
      [field.code]: [...curr[field.code], newIndex],
    }));

    // Update form values
    setValue(field.code, [...currentValues, {}], {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const removeFromDynamicArray = field => {
    const currentValues = getValues(field.code) || [];
    const currentPositions = dynamicFields[field.code];

    if (currentPositions.length === 0) return;

    const removedIndex = currentPositions[currentPositions.length - 1];

    // Update dynamic fields state
    setDynamicFields(curr => ({
      ...curr,
      [field.code]: curr[field.code].slice(0, -1),
    }));

    // Update form values
    setValue(field.code, currentValues.slice(0, -1), {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    // Unregister removed fields
    unregister(`${field.code}.${removedIndex}`);
  };

  // Simplify renderField to just return null for hidden fields
  const renderField = (field, parentField = null, arrayIndex = 0) => {
    const code = parentField ? `${parentField.code}.${arrayIndex}.${field.code}` : field.code;

    let conditionsMet = true;
    const formValues = { ...getValues(), ...idRequirements.selectedIds };
    if (field.conditions) {
      conditionsMet = evaluateFieldConditions(field.conditions, formValues, {
        parentField,
        arrayIndex,
        fields: details.fields,
      });
    }

    if (!conditionsMet) {
      if (field.code in formValues) {
        register(field.code, { required: false, validate: {} });
      }
      return null;
    }

    const required = field.required;
    const title = field.title ? `${field.title} ${required ? '*' : ''}` : '';
    const type = field.type;
    const description = field.description;
    const tooltip = field.tooltip;
    const autocomplete = field.autocomplete; // for text, phone_number, date, date_selector
    const placeholder = field.placeholder;
    const defaultValue = field.default_value;
    const min = field.min; // string_length for text, number for numbers, min date for date & date_selector
    const max = field.max; // string_length for text, number for numbers, max date for date & date_selector and size for array
    const validatorName = field.validator_name;
    const validationParams = field.validation_params;
    // radio_button & select:
    const fieldOptions = field.options;
    // select:
    const useDefault = field.use_default;
    const useSearch = field.use_search;

    // Derived:
    let error = null;
    let errorObject = null;

    if (['object', 'dynamic_array', 'array'].includes(type)) {
      error = wrapperValidators[code]?.error;
    } else {
      errorObject = errors?.[code];
      error = errorObject?.message;
    }

    switch (type) {
      case 'object':
        return (
          <InputGroup key={code} title={title} description={description} tooltip={tooltip}>
            <div className={cn('card', 'card-with-border', 'u-padding')}>
              {field.sub_fields.map(subField => renderField(subField))}
            </div>
            {error && <span className={styles.error}>{error}</span>}
          </InputGroup>
        );
      case 'dynamic_array':
        const dynamicArrayPositions = dynamicFields[code];
        return (
          <InputGroup key={code} title={title} description={description} tooltip={tooltip}>
            <div className={cn('card', 'card-with-border', 'u-padding')}>
              {dynamicArrayPositions?.map(index => (
                <InputGroup
                  key={`${code}-${index}`}
                  title={`${index + 1}. ${field.array_item_titles || field.title}`}>
                  <div className={cn('card', 'card-with-border', 'u-padding')}>
                    {field.sub_fields?.map(subField => renderField(subField, field, index))}
                  </div>
                </InputGroup>
              ))}
              <div className={cn('u-flex-box', 'u-flex-justify-between')}>
                <CustomButton
                  brand={brand}
                  className="u-margin-top--0 u-width-auto"
                  small
                  disabled={field.max && dynamicArrayPositions?.length >= field.max}
                  onClick={() => addToDynamicArray(field)}>
                  Add {field.array_item_titles || field.title}
                </CustomButton>
                <Button
                  type="delete"
                  disabled={dynamicArrayPositions?.length < 1}
                  onClick={() => removeFromDynamicArray(field)}>
                  Remove Last
                </Button>
                {field.max && dynamicArrayPositions?.length >= field.max && (
                  <FormMessage type={MessageType.Info}>
                    Maximum limit of {field.max} reached
                  </FormMessage>
                )}
              </div>
            </div>
            {error && <span className={styles.error}>{error}</span>}
          </InputGroup>
        );
      case 'array':
        const staticArrayPositions = [];
        for (let i = 0; i < field.max; i++) {
          staticArrayPositions[i] = i;
        }

        return (
          <InputGroup key={code} title={title} description={description} tooltip={tooltip}>
            <div className={cn('card', 'card-with-border', 'u-padding')}>
              {staticArrayPositions?.map(index => {
                // Get the specific title for this array item
                let itemTitle;
                let arrayItemTitle;
                if (Array.isArray(field.array_item_titles)) {
                  const itemData = field.array_item_titles[index] || field.array_item_titles[0];

                  if (itemData && typeof itemData === 'object') {
                    const values = Object.values(itemData).filter(
                      value => value && value.trim() !== '',
                    );
                    itemTitle = values.length > 0 ? values.join(' - ') : field.title;
                  } else {
                    itemTitle = itemData || field.title;
                    arrayItemTitle = itemData; // Store for template replacement
                  }
                } else {
                  itemTitle = field.array_item_titles || field.title;
                  arrayItemTitle = field.array_item_titles;
                }

                return (
                  <InputGroup key={`${code}-${index}`} title={`${index + 1}. ${itemTitle}`}>
                    <div className={cn('card', 'card-with-border', 'u-padding')}>
                      {field.sub_fields.map(subField => {
                        // Safely check if default_value is a string and contains the template
                        if (
                          typeof subField.default_value === 'string' &&
                          subField.default_value.includes('{{arrayItemTitle}}') &&
                          arrayItemTitle
                        ) {
                          subField = {
                            ...subField,
                            default_value: subField.default_value.replace(
                              '{{arrayItemTitle}}',
                              arrayItemTitle,
                            ),
                          };
                        }
                        return renderField(subField, field, index);
                      })}
                    </div>
                  </InputGroup>
                );
              })}
            </div>
            {error && <span className={styles.error}>{error}</span>}
          </InputGroup>
        );

      case 'hidden':
        return null;

      default:
        return (
          <DynamicTypeInput
            key={code}
            fieldName={code}
            type={type}
            title={title}
            description={description}
            tooltip={tooltip}
            autoComplete={autocomplete}
            placeholder={placeholder}
            defaultValue={defaultValue}
            min={min}
            max={max}
            required={required}
            validatorName={validatorName}
            validationParams={validationParams}
            fieldOptions={fieldOptions}
            useDefault={useDefault}
            useSearch={useSearch}
            locale={locale}
            register={register}
            watch={watch}
            control={control}
            setValue={setValue}
            getValues={getValues}
            error={error}
            noErrorMessage={false}
            errorObject={errorObject}
          />
        );
    }
  };

  // Set rejected values if any
  useEffect(() => {
    if (
      !check_values?.rejected ||
      check_values?.rejected_values?.values?.length < 1 ||
      check_values?.rejected_fixes?.values
    )
      return;

    check_values.rejected_values?.values.forEach(rejectedValue => {
      setError(rejectedValue, { type: 'custom' });
    });
  }, [check_values]);

  // Fix rejected values
  const fixRejectedValues = async () => {
    setLoading(true);
    await dispatch(
      saveCandidateChecksValues({
        token,
        data: { rejected_fixes: { ...check_values.rejected_fixes, values: true } },
      }),
    );
    clearErrors();
    setLoading(false);
  };

  const onSubmit = async values => {
    setLoading(true);
    const formValues = { ...values, ...idRequirements.selectedIds };

    if (watchSubscription) {
      watchSubscription.unsubscribe();
    }

    try {
      details.fields.forEach(field => {
        clearHiddenValues(field, formValues, details.fields, unregister);
      });

      const updatedValues = getValues(); // Pulls updated form values after unregistering

      const ADDRESS_KEYS = ADDRESS_FIELDS.map(f => f.fieldName);

      // Recursive function to check and unregister address fields
      const checkAndUnregisterAddressFields = (obj, parentKey = '') => {
        if (!obj || typeof obj !== 'object') return;

        Object.entries(obj).forEach(([key, value]) => {
          const fullKey = parentKey ? `${parentKey}.${key}` : key;

          if (
            ADDRESS_KEYS.includes(key) &&
            (value === '' || value === null || value === undefined)
          ) {
            console.log('Unregistering address field:', fullKey);
            unregister(fullKey);
          } else if (typeof value === 'object' && value !== null) {
            // Recursively check nested objects
            checkAndUnregisterAddressFields(value, fullKey);
          }
        });
      };

      // Start the recursive check from the root
      checkAndUnregisterAddressFields(updatedValues);

      let processedValues = getValues();

      processedValues.submitted = true;

      // Add hidden field processors
      details.fields.forEach(field => {
        if (field.type === 'hidden' && field.frontend_data_config?.processor) {
          processedValues[field.code] = field.value;
        }
      });

      // Added to system_values in candidates_checks_values for future use where support don't need their form cluttered with them but useful for bots etc
      const systemValues = {
        all_names: getAllNames(processedValues),
      };

      await dispatch(
        saveCandidateChecksValues({
          token,
          data: {
            values: processedValues,
            system_values: systemValues,
          },
        }),
      );

      setLoading(false);
      navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
    }
  };

  const getFieldTitle = code => {
    const field = details?.fields?.find(f => f.code === code);
    return field?.title || code;
  };

  const flattenErrors = (errorsObj, parentPath = '') => {
    const flattened = [];

    Object.entries(errorsObj).forEach(([key, value]) => {
      const path = parentPath ? `${parentPath}.${key}` : key;

      if (value?.message) {
        flattened.push({ field: path, message: value.message });
      }

      // Dive into nested errors (arrays/objects)
      if (typeof value === 'object' && !value.message) {
        flattened.push(...flattenErrors(value, path));
      }
    });

    return flattened;
  };

  const prettifyFieldPath = path =>
    path
      .split('.')
      .filter(segment => !/^\d+$/.test(segment)) // remove index numbers
      .map(segment => segment.replace(/_/g, ' '))
      .join(' → ');

  return (
    <CandidatePage loading={loading} token={token} withTopLogo brand={brand}>
      <CandidatePage.View>
        <CandidatePage.Card className={styles.root}>
          <img src="assets/images/icons/id_icon.svg" className={styles.icon} />
          <h1 className="title-4 u-padding-top u-padding-bottom--large">Your Details</h1>
          <p className="t-subtitle u-margin-bottom">
            Please provide the following details. Please ensure that all the information is
            accurate, as it will be shared directly with the relevant entities.
          </p>
          {check_values?.rejected &&
            check_values?.rejected_values?.values?.length > 0 &&
            !check_values?.rejected_fixes?.values && (
              <>
                <FormMessage
                  className="u-margin-0"
                  message={
                    check_values?.rejected_values?.reason || 'Please verify the details below.'
                  }
                  type={MessageType.Error}
                />
                <FormMessage
                  type={MessageType.Error}
                  message={
                    'There are some fields that require your attention. Please review the fields in red and click the "Fixed" button, and then save again.'
                  }
                />
              </>
            )}
          <form onSubmit={handleSubmit(onSubmit)} className="u-width-100">
            {details?.fields.map(field => renderField(field))}
            {check_values?.rejected && Object.keys(errors).length > 0 && (
              <Button
                type="warn"
                onClick={fixRejectedValues}
                className="u-margin-top--large u-width-100">
                Fixed
              </Button>
            )}
            <CustomButton
              submit
              small
              className="u-margin-top--large"
              brand={brand}
              // disabled={Object.keys(errors).length > 0 || anyComplexInputsInvalid}>
              disabled={!isValid || anyComplexInputsInvalid}>
              Save
            </CustomButton>
            {!isValid && (Object.keys(touchedFields).length > 0 || isDirty) && (
              <div className={cn('u-margin-top--medium')}>
                <div className={cn(styles.error)}>
                  Can’t save yet? Some fields require your attention.
                </div>
                <Button
                  type="secondary"
                  small
                  className="u-width-auto u-margin-bottom--small"
                  onClick={async () => {
                    await trigger(); // validate and reveal errors
                    setShowErrorList(prev => !prev);
                  }}>
                  {showErrorList ? 'Hide what’s missing' : 'See what’s missing'}
                </Button>

                {showErrorList && (
                  <div className={cn('card', 'card-with-border', 'u-padding')}>
                    {flattenErrors(errors).map(({ field, message }) => {
                      const title = prettifyFieldPath(field);
                      return (
                        <div
                          key={field}
                          className={cn(styles.errorListItem, 'u-margin-bottom--xsmall')}>
                          <strong style={{ color: 'inherit' }}>{title}</strong>:{' '}
                          {message || 'This field is required.'}
                        </div>
                      );
                    })}
                    {Object.values(wrapperValidators).map(({ code, error }) => {
                      if (!error) return null;
                      const title = getFieldTitle(code).toLowerCase();
                      return (
                        <div
                          key={code}
                          className={cn(styles.errorListItem, 'u-margin-bottom--xsmall')}>
                          <strong style={{ color: 'inherit' }}>{title}</strong>: {error}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </form>
        </CandidatePage.Card>
      </CandidatePage.View>
    </CandidatePage>
  );
};

export default CandidateDetails;
