import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiRequest from 'helpers/api';

import { processIdRequirementConfigs } from '../helpers/candidate_application/id-requirements';
import { processDynamicContent } from '../helpers/candidate_application/process-dynamic-content';
import { processConstantTemplates } from '../helpers/candidate_application/process-template-constants';

const initialState = {
  fetched: false, // Data fetched

  // Original application data
  application: {
    locale: 'en_GB',
    country: 'NZ',
    id_verification_enabled: true,
    candidate: {},
    brand: {},
    id_verification: {},
    candidate_checks: [],
    check_types: [],
    forms: [],
    uploads: [],
    check_values: {
      values: {},
      system_values: {},
    },
    candidate_fields: [],
    id_requirements: [],
    id_field_codes: [],
  },

  ids: null, // IDs
  idVerification: null, // Id Verification
  documentUploads: null, // Document Uploads
  candidatePhoto: null, // Candidate Selfie
  details: {
    fields: [],
    completed: false,
  },
  idRequirements: {
    requirements: [],
    idFieldCodes: [],
    selectedIds: {},
    idUploadOptions: [],
  },
};

const processTemplatesInFields = rawFields => {
  if (!rawFields) return [];

  const processFieldsRecursively = field => {
    const processedField = processConstantTemplates(field);

    if (processedField.sub_fields) {
      processedField.sub_fields = processedField.sub_fields.map(processFieldsRecursively);
    }

    return processedField;
  };

  const processedFields = rawFields.map(processFieldsRecursively);

  return processedFields;
};

const checksIds = ({ uploads, check_types }) => {
  // Checks with ids required
  const checksTypesWithId = check_types.filter(checkType => checkType.id_types?.length > 0);

  // If no checks with ids required
  if (checksTypesWithId.length < 1) return null;

  // Check if all checks are satisfied
  const uploadTypes = uploads.map(u => u.metadata.id_type);
  let completed = true;

  if (uploadTypes.length > 0) {
    checksTypesWithId.forEach(checkType => {
      checkType.id_types.forEach(checkIdBatch => {
        if (checkIdBatch.filter(type => uploadTypes.includes(type)).length < 1) {
          completed = false;
        }
      });
    });
  } else {
    completed = false;
  }

  return { completed, checks: checksTypesWithId };
};

const checksCandidatePhoto = ({ uploads, check_types }) => {
  // Checks virtual_id required
  const checkTypesWithCandidatePhoto = check_types.filter(check_type => check_type.candidate_photo);

  // If no checks return
  if (checkTypesWithCandidatePhoto.length < 1) return null;

  const uploadsWithCandidatePhoto = uploads.filter(u => u.metadata.virtual_id);

  if (uploadsWithCandidatePhoto.length < 1) return { completed: false };

  return { completed: true };
};

const checksIdVerification = ({ check_types, id_verification_enabled, id_verification }) => {
  if (!id_verification_enabled) return { required: false };

  const checkTypesWithIdVerification = check_types.filter(
    check_type => check_type.has_identity_verification,
  );
  if (checkTypesWithIdVerification < 1) return { required: false };

  return {
    ...id_verification,
    required: true,
  };
};

const checksDetailsFields = (fields, checkValues) => {
  if (!fields) return null;

  const processDynamicFields = initialFields => {
    const queue = initialFields.map(field => ({
      field,
      parentField: null,
      arrayIndex: 0,
    }));
    const processedFields = [...initialFields];

    while (queue.length > 0) {
      const { field, parentField, arrayIndex } = queue.shift();

      if (field?.frontend_data_config) {
        processDynamicContent(field, checkValues?.values, {
          parentField,
          arrayIndex,
        });
      }

      if (Array.isArray(field?.sub_fields) && field.sub_fields.length > 0) {
        field.sub_fields.forEach(subField => {
          queue.push({
            field: subField,
            parentField: field,
            arrayIndex,
          });
        });
      }
    }

    return processedFields;
  };

  return {
    fields: processDynamicFields(fields),
  };
};

const checksDocuments = ({ check_types, uploads }) => {
  // Checks with documents required
  const checksTypesWithDocuments = check_types.filter(
    checkType => checkType.document_uploads?.length > 0,
  );

  // If no checks with documents required
  if (checksTypesWithDocuments.length < 1) return null;

  // Get uploads types
  const uploadTypes = uploads.map(u => u.metadata.doc_type);

  // Get documents
  let documents = [];
  checksTypesWithDocuments.forEach(checkType => {
    documents = [...documents, ...checkType.document_uploads];
  });

  // Check for completion
  let completed = true;
  // Validate (for non-required documents)
  let valid = true;

  documents.forEach(document => {
    const includesDocument = uploadTypes?.includes(document.code);
    if (!includesDocument) {
      completed = false;
    }
    if (document.required && !includesDocument) {
      valid = false;
    }
  });

  return { completed, valid, documents: documents };
};

export const getCandidateApplication = createAsyncThunk('getCandidateApplication', async token => {
  const res = await apiRequest(`candidate_applications/${token}`, {}, 'get');
  return res.result;
});

export const saveCandidateChecksValues = createAsyncThunk(
  'saveCandidateChecksValues',
  async ({ token, data }) => {
    const res = await apiRequest(`candidates_checks_values/token/${token}`, data, 'post');
    return res.result;
  },
);

export const updateCandidateCheck = createAsyncThunk(
  'updateCandidateCheck',
  async ({ token, id, data }) => {
    const res = await apiRequest(`candidate_checks/token/${token}/id/${id}`, data, 'put');
    return res.result;
  },
);

export const getAmlResult = createAsyncThunk('getAmlResult', async id => {
  const res = await apiRequest(`candidate_checks/${id}/aml`, {}, 'get');
  return res.result;
});

export const submitCandidateApplication = createAsyncThunk(
  'submitCandidateApplication',
  async ({ token, user_agent = null }) => {
    const res = await apiRequest(`candidate_checks/token/${token}`, { user_agent }, 'post');
    return res.result;
  },
);

export const getCandidateUpload = createAsyncThunk('getCandidateUpload', async ({ token, id }) => {
  const res = await apiRequest(`candidates/token/${token}/uploads/${id}`, {}, 'get');
  return res.result;
});

export const signCandidateUpload = createAsyncThunk(
  'signCandidateUpload',
  async ({ token, params }) => {
    const res = await apiRequest(`candidates/token/${token}/uploads/sign_upload`, params, 'get');
    return res.result;
  },
);

export const createCandidateUpload = createAsyncThunk(
  'createCandidateUpload',
  async ({ token, params }) => {
    const res = await apiRequest(`candidates/token/${token}/uploads`, params, 'post');
    return res.result;
  },
);

export const createBase64CandidateUpload = createAsyncThunk(
  'createBase64CandidateUpload',
  async ({ token, params }) => {
    const res = await apiRequest(`candidates/token/${token}/uploads/base64_upload`, params, 'post');
    return res.result;
  },
);

export const deleteCandidateUpload = createAsyncThunk(
  'deleteCandidateUpload',
  async ({ token, id }) => {
    const res = await apiRequest(`candidates/token/${token}/uploads/${id}`, {}, 'delete');
    return res.result;
  },
);

export const saveCustomReference = createAsyncThunk(
  'saveCustomReference',
  async ({ token, id, referees, user_agent }) => {
    const res = await apiRequest(
      `candidate_checks/token/${token}/id/${id}/custom_reference`,
      { referees, user_agent },
      'post',
    );
    return res.result;
  },
);

export const getFormByRefereeId = createAsyncThunk(
  'getFormByRefereeId',
  async ({ token, refereeId }) => {
    const res = await apiRequest(`forms/token/${token}/referee/${refereeId}`, {}, 'get');
    return res.result;
  },
);

export const saveCheckFormAnswers = createAsyncThunk(
  'saveCheckFormAnswers',
  async ({ token, id, params }) => {
    const res = await apiRequest(
      `candidate_checks/token/${token}/id/${id}/save_form_answers`,
      params,
      'post',
    );
    return res.result;
  },
);

export const getCandidateReferenceTypeformParams = createAsyncThunk(
  'getCandidateReferenceTypeformParams',
  async ({ token, id, replacement = false }) => {
    const res = await apiRequest(
      `candidate_checks/token/${token}/id/${id}/candidate_reference_typeform_params`,
      { replacement },
      'get',
    );
    return res.result;
  },
);

export const updateBackyCheck = createAsyncThunk(
  'getCandidateReferenceTypeformParams',
  async ({ token, id }) => {
    const res = await apiRequest(`candidate_checks/token/${token}/id/${id}/backy_check`, {}, 'put');
    return res.result;
  },
);

export const updateDynamicFields = createAction('candidateApplication/updateDynamicFields');
export const updateIdFieldSelection = createAction('candidateApplication/updateIdFieldSelection');
export const updateIdUploadOptions = createAction('candidateApplication/updateIdUploadOptions');

const candidateApplicationSlice = createSlice({
  name: 'candidateApplication',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCandidateApplication.fulfilled, (state, action) => {
        const application = action.payload;
        const processedIdRequirements = processIdRequirementConfigs(application.id_requirements);
        const processedFields = processTemplatesInFields(application.candidate_fields);

        state.idRequirements = {
          ...processedIdRequirements,
          idFieldCodes: application.id_field_codes,
          selectedIds: {},
        };

        state.details = {
          fields: processedFields,
          completed: !!application.check_values?.values?.submitted,
        };

        state.application = action.payload;
        state.ids = checksIds(application);
        state.documentUploads = checksDocuments(application);
        state.candidatePhoto = checksCandidatePhoto(application);
        state.idVerification = checksIdVerification(application);
        state.signatureRequired = application.check_types.find(
          checkType => checkType.signature_required,
        );

        state.fetched = true;
      })
      .addCase(saveCandidateChecksValues.fulfilled, (state, { payload }) => {
        state.application.check_values = payload;
        state.details.completed = payload?.values?.submitted || false;
      })
      .addCase(updateCandidateCheck.fulfilled, (state, { payload }) => {
        state.application.candidate_checks = state.application.candidate_checks.map(
          candidate_check => (candidate_check.id === payload.id ? payload : candidate_check),
        );
      })
      .addCase(createCandidateUpload.fulfilled, (state, { payload }) => {
        state.application.uploads = [...state.application.uploads, payload];
        state.ids = checksIds(state.application);
        state.documentUploads = checksDocuments(state.application);
        state.candidatePhoto = checksCandidatePhoto(state.application);
      })
      .addCase(deleteCandidateUpload.fulfilled, (state, { payload }) => {
        state.application.uploads = [
          ...state.application.uploads.filter(upload => upload.id != payload),
        ];
        state.ids = checksIds(state.application);
        state.documentUploads = checksDocuments(state.application);
        state.candidatePhoto = checksCandidatePhoto(state.application);
      })
      .addCase(createBase64CandidateUpload.fulfilled, (state, { payload }) => {
        state.application.uploads = [...state.application.uploads, payload];
        state.ids = checksIds(state.application);
        state.documentUploads = checksDocuments(state.application);
        state.candidatePhoto = checksCandidatePhoto(state.application);
      })
      .addCase(saveCustomReference.fulfilled, (state, { payload }) => {
        state.application.candidate_checks = state.application.candidate_checks.map(
          candidate_check => (candidate_check.id === payload.id ? payload : candidate_check),
        );
      })
      .addCase(saveCheckFormAnswers.fulfilled, (state, { payload }) => {
        state.application.candidate_checks = state.application.candidate_checks.map(
          candidate_check => (candidate_check.id === payload.id ? payload : candidate_check),
        );
      })
      .addCase(getFormByRefereeId.fulfilled, (state, { payload }) => {
        state.application.candidate_checks = [
          ...state.application.candidate_checks,
          payload.reference_check,
        ];
        state.application.forms = [...state.application.forms, payload.candidate_form];
      })
      .addCase(updateDynamicFields, (state, action) => {
        const result = checksDetailsFields(state.details.fields, { values: action.payload });

        if (result) {
          state.details = {
            ...state.details,
            fields: result.fields,
            completed: result.completed,
          };
        }
      })
      .addCase(updateIdFieldSelection, (state, action) => {
        state.idRequirements.selectedIds = action.payload || {};
      })
      .addCase(updateIdUploadOptions, (state, action) => {
        state.idRequirements.idUploadOptions = action.payload;
      });
  },
});

export const selectCandidateApplication = state => state.candidateApplication;

export default candidateApplicationSlice.reducer;
